<template>
    <v-container class="terms">
        <v-row justify="center">
            <v-col>
                <h2 class="text-center ma-6">
                    DISCLAIMER AND TERMS OF USE AGREEMENT
                </h2>
                <h3 class="text-center ma-6">Introduction</h3>
                <p>This site is owned and operated by {{ businessName }}.</p>
                <p>
                    The author and publisher of this site and the accompanying
                    materials make no representation or warranties with respect
                    to the accuracy, applicability, fitness or completeness of
                    the contents of this site.
                </p>
                <p>
                    The author and publisher disclaim any warranties (express or
                    implied), merchantability, or fitness for any particular
                    purpose. The author and publisher shall in no event be held
                    liable to any party for any direct, indirect, punitive,
                    special, incidental or other consequential damages arising
                    directly or indirectly from any use of this material, which
                    is provided "as is", and without warranties.
                </p>
                <p>
                    Every effort has been made to accurately represent this
                    product and its potential. Any examples in these materials
                    are not be interpreted as a promise or guarantee of
                    earnings.
                </p>
                <p>
                    The author and publisher do not warrant the performance,
                    effectiveness or applicability of any sites listed or linked
                    to in this site. All links are for information purposes only
                    and are not warranted for content, accuracy or any other
                    implied or explicit purpose.
                </p>
                <p>
                    As always, the advice of a competent legal, tax, accounting
                    or other professional should be sought.
                </p>
                <p>
                    This site is © copyrighted and is protected under the US
                    Copyright Act of 1976 and all other applicable
                    international, federal, state and local laws, with ALL
                    rights reserved. No part of this may be copied, or changed
                    in any format, sold, or used in any way other than what is
                    outlined within this site under any circumstances without
                    express permission.
                </p>

                <h2 id="privacy" class="text-center ma-6">PRIVACY POLICY</h2>
                <p>
                    This site strives to offer its visitors the many advantages
                    of Internet technology and to provide an interactive and
                    personalized experience. We may use Personally Identifiable
                    Information (your name, e-mail address, street address,
                    telephone number) subject to the terms of this privacy
                    policy. We will never sell, barter, or rent your email
                    address to any unauthorized third party. Period.
                </p>

                <h3 class="text-center ma-6">
                    How we gather information from users
                </h3>
                <p>
                    How we collect and store information depends on the page you
                    are visiting, the activities in which you elect to
                    participate and the services provided.
                </p>
                <p>
                    Like most Web sites, this site collects information
                    automatically and through the use of electronic tools that
                    may be transparent to our visitors. For example, we may log
                    the name of your Internet Service Provider or use cookie
                    technology to recognize you and hold information from your
                    visit. Among other things, the cookie may store your user
                    name and password, sparing you from having to re-enter that
                    information each time you visit, or may control the number
                    of times you encounter a particular advertisement while
                    visiting our site. As we adopt additional technology, we
                    also may gather information through other means. In certain
                    cases, you can choose not to provide us with information,
                    for example by setting your browser to refuse to accept
                    cookies, but if you do you may be unable to access certain
                    portions of the site or may be asked to re-enter your user
                    name and password, and we may not be able to customize the
                    site's features according to your preferences.
                </p>

                <h3 class="text-center ma-6">What Information We Collect</h3>
                <p>
                    Some data will be collected automatically by Our Site. For
                    further details, please see section below on Our use of
                    Cookies. Other data will only be collected if you
                    voluntarily submit it and consent to Us using it, for
                    example, when signing up for an Account. Depending upon your
                    use of Our Site, We may collect some or all of the following
                    data:
                </p>

                <ul>
                    <li>Full name</li>
                    <li>
                        Contact information such as email addresses and
                        telephone numbers
                    </li>
                    <li>
                        Financial information such as credit / debit card
                        numbers, should you purchase one of our products
                    </li>
                    <li>Business/Company name</li>
                    <li>
                        Other information about your business (# years in
                        business, industry, etc.), should you request us to
                        write information about your Company on your behalf
                    </li>
                    <li>IP address (automatically collected)</li>
                    <li>
                        Web browser type and version (automatically collected)
                    </li>
                    <li>Operating system (automatically collected)</li>
                    <li>
                        A list of URLs starting with a referring site, your
                        activity on Our Site, and the site you exit to
                        (automatically collected)
                    </li>
                </ul>

                <h3 class="text-center ma-6">
                    How We Store the Information We Collect
                </h3>
                <p>
                    Some or all of your data may be stored or transferred
                    outside of the European Economic Area (“the EEA”) (The EEA
                    consists of all EU member states, plus Norway, Iceland and
                    Liechtenstein). You are deemed to accept and agree to this
                    by using Our Site and submitting information to Us. If We do
                    store or transfer data outside the EEA, We will take all
                    reasonable steps to ensure that your data is treated as
                    safely and securely as it would be within the EEA and under
                    the GDPR. Such steps may include, but not be limited to, the
                    use of legally binding contractual terms between Us and any
                    third parties We engage and the use of the EU-approved Model
                    Contractual Arrangements
                </p>
                <p>
                    We only store your data for as long as we need to in order
                    to use it as described above, and/or for as long as we have
                    your permission to keep it
                </p>
                <p>Our Data Retention Policies are:</p>
                <ul>
                    <li>
                        Data related to customer account information is retained
                        on our servers indefinitely, unless you request us to
                        remove it
                    </li>
                    <li>
                        Involuntarily-collected information (e.g. Cookies) is
                        retained for up to one year by web browsers, at which
                        point is typically deleted by the browser itself
                    </li>
                </ul>

                <h3 class="text-center ma-6">
                    What we do with the information we collect
                </h3>
                <p>
                    Like other Web publishers, we collect information to enhance
                    your visit and deliver more individualized content and
                    advertising. We respect your privacy and do not share your
                    information with anyone.
                </p>
                <p>
                    Aggregated Information (information that does not personally
                    identify you) may be used in many ways. For example, we may
                    combine information about your usage patterns with similar
                    information obtained from other users to help enhance our
                    site and services (e.g., to learn which pages are visited
                    most or what features are most attractive). Aggregated
                    Information may occasionally be shared with our advertisers
                    and business partners. Again, this information does not
                    include any Personally Identifiable Information about you or
                    allow anyone to identify you individually.
                </p>
                <p>
                    We may use Personally Identifiable Information collected on
                    this site to communicate with you about your registration
                    and customization preferences; our Terms of Service and
                    privacy policy; services and products offered by this site
                    and other topics we think you might find of interest.
                </p>
                <p>
                    Personally Identifiable Information collected by this site
                    also may be used for other purposes, including but not
                    limited to site administration, troubleshooting, processing
                    of ecommerce transactions, administration of sweepstakes and
                    contests, and other communications with you. Certain third
                    parties who provide technical support for the operation of
                    our site (our Web hosting service for example) may access
                    such information. We will use your information only as
                    permitted by law. In addition, from time to time as we
                    continue to develop our business, we may sell, buy, merge or
                    partner with other companies or businesses. In such
                    transactions, user information may be among the transferred
                    assets. We may also disclose your information in response to
                    a court order, at other times when we believe we are
                    reasonably required to do so by law, in connection with the
                    collection of amounts you may owe to us, and/or to law
                    enforcement authorities whenever we deem it appropriate or
                    necessary. Please note we may not provide you with notice
                    prior to disclosure in such cases.
                </p>

                <h3 class="text-center ma-6">
                    How we communicate using SMS or MMS
                </h3>
                <p>
                    Your phone number is used exclusively for the following
                    purposes:
                </p>
                <ul>
                    <li>
                        <p>
                            Sending important updates related to our services.
                        </p>
                    </li>
                    <li>
                        <p>
                            Providing information about your account or
                            transactions.
                        </p>
                    </li>
                    <li>
                        <p>
                            Notifying you about changes to our terms, policies,
                            or services.
                        </p>
                    </li>
                </ul>
                <h3 class="text-center ma-6">
                    How to opt out of communication via SMS or MMS
                </h3>
                <p>
                    If you wish to opt out of receiving SMS messages from us,
                    please follow the instructions provided in each SMS.
                    Typically, you can opt out by replying with the keyword
                    "STOP." Once you opt out, you will no longer receive SMS
                    messages from us.
                </p>

                <h3 class="text-center ma-6">
                    Affiliated sites, linked sites and advertisements
                </h3>
                <p v-if="isAmpifire">
                    The brand AmpiFire and this website are owned and operated
                    by MindQuo LTD.
                </p>
                <p>
                    This site expects its partners, advertisers and affiliates
                    to respect the privacy of our users. Be aware, however, that
                    third parties, including our partners, advertisers,
                    affiliates and other content providers accessible through
                    our site, may have their own privacy and data collection
                    policies and practices. For example, during your visit to
                    our site you may link to, or view as part of a frame on a
                    this site page, certain content that is actually created or
                    hosted by a third party. Also, through this site you may be
                    introduced to, or be able to access, information, Web sites,
                    features, contests or sweepstakes offered by other parties.
                    this site is not responsible for the actions or policies of
                    such third parties. You should check the applicable privacy
                    policies of those third parties when providing information
                    on a feature or page operated by a third party.
                </p>
                <p>
                    You should also be aware that if you voluntarily disclose
                    Personally Identifiable Information on message boards or in
                    chat areas, that information can be viewed publicly and can
                    be collected and used by third parties without our knowledge
                    and may result in unsolicited messages from other
                    individuals or third parties. Such activities are beyond the
                    control of this site and this policy.
                </p>
                <p>
                    Data collected on your usage of this site, including but not
                    limited to, the pages you visit, can be used to serve you
                    ads on this site and other sites across the internet. This
                    usage data may be collected by third party advertising
                    networks, including but not limited to Google, SiteScout,
                    retargeting sites, remarketing sites, Facebook, and YouTube
                    with the purpose to serve ads based on your past usage of
                    this site
                </p>

                <h3 class="text-center ma-6">Cookies</h3>
                <p>
                    We may place a text file called a "cookie" in the browser
                    files of your computer. We may use cookies to store visitor
                    site preferences and record session information.
                </p>
                <p>
                    You can choose not to accept cookies from this site by
                    changing the security settings in your web browser. However,
                    some functions of this site may be interrupted as a result.
                </p>
                <p>
                    While on our site, our advertisers, promotional partners or
                    other third parties may use cookies or other technology to
                    attempt to identify some of your preferences or retrieve
                    information about you. For example, some of our advertising
                    is served by third parties and may include cookies that
                    enable the advertiser to determine whether you have seen a
                    particular advertisement before. Other features available on
                    our site may offer services operated by third parties and
                    may use cookies or other technology to gather information.
                    this site does not control the use of this technology by
                    third parties or the resulting information, and is not
                    responsible for any actions or policies of such third
                    parties.
                </p>

                <h3 class="text-center ma-6">
                    How to Access Your Data and/or Rescind Consent to Store Data
                </h3>
                <p>
                    You have the legal right to ask for a copy of any of your
                    personal data held by Us (where such data is held). You also
                    have the right to rescind permission for us to store that
                    data on our site. Please contact us by emailing
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                    to request a copy of your data and/or to revoke permission
                    for us to access it.
                </p>

                <h3 class="text-center ma-6">Google Ads</h3>
                <ul>
                    <li>
                        <p>
                            Google, as a third party vendor, may use cookies to
                            serve ads on this site.
                        </p>
                    </li>
                    <li>
                        <p>
                            Google's use of the DART cookie enables it to serve
                            you ads based on your visit to this site and other
                            sites on the Internet.
                        </p>
                    </li>
                    <li>
                        <p>
                            You may opt out of the use of the DART cookie by
                            visiting the Google ad and content network privacy
                            policy.
                        </p>
                    </li>
                </ul>

                <h3 class="text-center ma-6">Children</h3>
                <p>
                    This site does not knowingly collect or solicit Personally
                    Identifiable Information from or about children under 13
                    except as permitted by law. If we discover we have received
                    any information from a child under 13 in violation of this
                    policy, we will delete that information immediately. If you
                    believe this site has any information from or about anyone
                    under 13, please contact us at
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                </p>

                <h3 class="text-center ma-6">Changes to this Policy</h3>
                <p>
                    This site reserves the right to change this policy at any
                    time. Please check this page periodically for changes. Your
                    continued use of our site following the posting of changes
                    to these terms will mean you accept those changes.
                    Information collected prior to the time any change is posted
                    will be used according to the rules and laws that applied at
                    the time the information was collected.
                </p>

                <h2 class="text-center ma-6">
                    DISCLAIMER - COMPENSATORY AFFILIATION
                </h2>
                <p>
                    This site may be affiliated with companies whose products
                    and services are being promoted on this site, as a Marketing
                    Affiliate and receive compensation for sales of such
                    products and services.
                </p>

                <h2 class="text-center ma-6">
                    EARNINGS DISCLAIMER AND FORWARD LOOKING STATEMENTS
                </h2>
                <p>
                    Every effort has been made to accurately represent the
                    products and services found on this site and their
                    potential. However, there is no guarantee that a customer
                    will earn any money using the products and services found on
                    this site, or the techniques or ideas in these materials.
                    Examples in these materials are not to be interpreted as a
                    promise or guarantee of earnings in any way.
                </p>
                <p>
                    Materials in the product and on our website may contain
                    information that includes or is based upon Forward-Looking
                    Statements within the meaning of the Securities Litigation
                    Reform Act of 1995. Forward-Looking Statements present our
                    expectations or forecasts of future events. You can identify
                    these statements by the fact that they do not relate
                    strictly to historical or current facts. Such
                    Forward-Looking Statements use words such as "anticipate,"
                    "estimate," "expect," "project," "intend," "plan,"
                    "believe," and other such words and terms of similar meaning
                    in connection with a description of potential earnings or
                    financial performance. Your results may vary from ours and
                    those of anyone else that is, or will be using these
                    products, techniques, and/or services.
                </p>
                <p>
                    Any and all Forward-Looking Statements here or on any of our
                    sales material are intended to express our opinion of
                    earnings potential not actual earnings expected. Many
                    factors will be important in determining your actual results
                    and no guarantees are made, expressed or implied that you
                    will achieve results similar to ours or anybody else's.
                </p>

                <h2 class="text-center ma-6">
                    REFUND, CANCELLATION AND CARRYFORWARD POLICY
                </h2>
                <p>
                    Each cancellation request must be officially communicated to
                    {{ businessName }} via
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                    .
                </p>

                <h3 class="text-center ma-6">
                    Specific guidelines regarding cancellations:
                </h3>
                <p>
                    All {{ businessName }}
                    product cancellation requests must be communicated at least
                    3 business days prior to their official renewal date.
                </p>
                <p>
                    Cancellations made outside of the specified time-frame will
                    be accepted but do not qualify for a refund.
                </p>
                <p>
                    Steps required in order to successfully cancel a
                    subscription:
                </p>
                <ol class="pb-4">
                    <li>
                        Customer must request cancellation by emailing
                        <a :href="'mailto:' + businessEmail">
                            {{ businessEmail }}
                        </a>
                        .
                    </li>
                    <li>
                        Support will confirm the receipt of the request to the
                        customer.
                    </li>
                    <li>
                        {{ businessName }}
                        will address the request.
                    </li>
                </ol>
                <p>
                    <strong>Important:</strong>
                    Responding to all communications from
                    {{ businessName }}
                    , especially to confirm cancellation requests, is highly
                    recommended in order to prevent unwanted charges.
                </p>
                <p>
                    {{ businessName }}
                    might take a certain period of time to address any
                    misunderstandings or issues that motivated the customer's
                    cancellation request.
                </p>
                <p>
                    Nevertheless, if the customer discontinues further use of
                    the subscription, and no resolution is reached for their
                    concerns,
                    {{ businessName }}
                    will honor the initial cancellation request date.
                </p>
                <p>
                    Should a cancellation request meeting the above conditions
                    not be processed by
                    {{ businessName }}
                    before the subscription renewal date,
                    {{ businessName }}
                    will refund the customer for any fees incurred.
                </p>

                <h3 class="text-center ma-6">
                    Specific guidelines regarding refunds:
                </h3>
                <ol class="pb-4">
                    <li>
                        {{ businessName }} customers are not entitled to refunds
                        as the general rule. However, occasional refunds may be
                        awarded as a gesture of good-will.
                        <br />
                        Each request will be assessed by the
                        {{ businessName }} team, and the decision to refund
                        depend exclusively on {{ businessName }}.
                    </li>
                    <li>
                        Some special promotions are covered by a 60-day money
                        back guarantee, where the customer is entitled to a full
                        refund on the initial purchase. For this guarantee to be
                        applicable, it has to be displayed within the Sales page
                        of a specific promotion.
                        <br />
                        Subsequent purchases are not covered by this 60-day
                        money back guarantee.
                    </li>
                    <li>
                        {{ businessName }} has a strict policy regarding any
                        refunds it decides to issue of over $8,000.00; as these
                        will be reimbursed exclusively via Bank transfer.
                        <br />
                        In the case of a refund exceeding the $8,000.00
                        threshold, the customer agrees to provide their banking
                        details when requested, so the refund can be issued.
                    </li>
                    <li>
                        No partial refunds will be issued regarding subscription
                        charges; However, partial refunds may be granted for
                        non-subscription products and services.
                    </li>
                    <li>
                        The refund process as a whole can take up to 2-3 weeks,
                        and it generally (with exceptions) presents the
                        following structure:
                        <br />
                        <strong>
                            <em>
                                Customer requests refund to
                                {{ businessName }}'s support team > Support
                                assesses the refund request >
                                {{ businessName }} decides to award a refund or
                                not, and communicates the decision to the
                                customer > If awarded, {{ businessName }}'s
                                Finance department issues the refund > Payment
                                processor (or bank) receives the money > Payment
                                processor (or bank) credits the money to
                                customer's account.
                            </em>
                        </strong>
                    </li>
                </ol>
                <p>
                    Customers must refrain from raising disputes or Charge-backs
                    while an internal refund process is ongoing. Especially if
                    {{ businessName }}'s support team has informed the customer
                    that the refund request is being processed.
                </p>
                <p>
                    If the customer were to raise a dispute or a charge-back
                    while the refund assessment process is ongoing, it will
                    result in a failed attempt to refund, as it will be
                    automatically blocked by the billing platform.
                </p>
                <p>
                    Requesting a refund and raising a dispute or charge-back at
                    the same time could be considered as a fraud attempt, as
                    there is an intent to be refunded twice. Customers may have
                    their right of service revoked at {{ businessName }}'s
                    discretion, including access to their
                    {{ businessName }} accounts. Additionally, payment
                    processors might flag the customer's account as fraudulent
                    and restrict access to it.
                </p>
                <p>
                    Customers that file credit card or PayPal disputes
                    untruthfully or incorrectly may have their right of service
                    revoked at our discretion.
                </p>
                <p>
                    Asigo System customers are solely responsible for disputes,
                    charge-backs, and refunds resulted from transactions with
                    their end clients. Furthermore,
                    {{ businessName }} reserves the right to withdraw revenues
                    should customers fail to do so.
                </p>
                <p>
                    Customers that do not pay their
                    {{ businessName }} product fees (monthly subscriptions,
                    annual subscriptions or split-pay packages) may have their
                    right of service revoked at our discretion, including access
                    to their {{ businessName }} accounts. Non-paid fees are
                    defined as any delinquent payments for
                    {{ businessName }} products that are not resolved (paid in
                    full) by the customer within 27 days of their original
                    payment due date. For split-pay packages, Customers agree to
                    pay the total fee of the package over consecutive monthly
                    installments, as defined by {{ businessName }}, and to a
                    fractioned delivery of full package credits, in equal
                    amounts, per paid installment.
                </p>
                <p>
                    As a general matter, prepayments for distribution services
                    do not have a time limit and the company's undertaking to
                    provide such distribution services does not expire as of a
                    certain date. However, the company shall not be obligated to
                    provide such distribution service if any of the following
                    three factors shall be in effect:
                </p>
                <ul>
                    <li>
                        This site is no longer in the business of distributing
                        news articles;
                    </li>
                    <li>
                        Suitable distribution channels are not available; or
                    </li>
                    <li>
                        The cost of distribution has risen to such an extent
                        that this site cannot economically provide such service
                        given the amount prepaid.
                    </li>
                </ul>

                <h2 class="text-center ma-6">DMCA DISCLAIMER</h2>
                <p>
                    This site respects the intellectual property of others. If
                    you believe that your work has been copied in a way that
                    constitutes copyright infringement, please follow our Notice
                    and Procedures for Making Claims of Copyright Infringement
                    below.
                </p>

                <h3 class="text-center ma-6">
                    Notice and Procedure for Making Claims of Copyright
                    Infringement Pursuant to the Digital Millennium Copyright
                    Act
                </h3>
                <p>
                    If you believe that you hold a claim of copyright
                    infringement against this site, submit notice of your claim
                    via email to
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                </p>
                <p>
                    Email Address to Which Notification Should Be Sent:
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                </p>
                <p>
                    To be effective, the notification of your claim of copyright
                    infringement should be written and should include the
                    following:
                </p>
                <ul>
                    <li>
                        <p>
                            A statement that you are the owner of the exclusive
                            right you claim has been infringed, or a statement
                            that you are authorized to act on behalf of the
                            owner of an exclusive right that has allegedly been
                            infringed.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            A statement, under penalty of perjury, that the
                            information in the notification is accurate. Your
                            signature. (The signature may be electronic.)
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            The identification of the copyrighted work you claim
                            has been infringed. (If you claim that multiple
                            copyrighted works have been infringed, you can
                            submit one notification with a list of the allegedly
                            infringed works.)
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            Identification of the material that you claim to be
                            infringing and information reasonably sufficient to
                            permit this site to locate the material.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            Information reasonably sufficient to permit this
                            site to contact you, including your address,
                            telephone number, fax number and electronic mail
                            address. You can provide contact information for the
                            owner of the exclusive right that you claim has been
                            infringed if you are not the owner, but rather
                            authorized to act on behalf of the owner.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            A statement you have a good-faith belief that use of
                            the material in the manner complained of is not
                            authorized by the copyright owner, its agent, or the
                            law.
                        </p>
                    </li>
                </ul>
                <p>
                    This site will respond to any DMCA complaints in a timely
                    manner. Should you be the copyright holder of a specific
                    piece of content featured on this site without your
                    permission, this site will remove it in a timely manner once
                    notified.
                </p>

                <h3 id="amp-guidelines" class="text-center ma-8">
                    News Article Content Rules and Terms
                </h3>
                <p>
                    The documents submitted to
                    {{ businessName }} shall not contain any content that is
                    obscene, libelous, slanderous or otherwise defamatory, false
                    or misleading or which violates any copyright, right of
                    privacy or publicity or other right of any person and agree
                    to comply with all applicable laws, rules and regulations,
                    including to all those applicable laws, rules and
                    regulations relating to "SPAM".
                </p>
                <p>
                    Under all circumstances,
                    {{ businessName }} reserves the right at all times and
                    without previous notification to approve, disapprove or
                    remove parts or all the published/submitted documents as
                    long as it is inappropriate to be published. Any decision
                    made by the {{ businessName }} team shall be FINAL and it
                    cannot be questioned.
                </p>
                <p>
                    Under all circumstances, content distributed by
                    {{ businessName }} can be removed without prior
                    notification. Distribution partners receiving
                    {{ businessName }}'s content reserve the right to remove any
                    or all content sourced from {{ businessName }} at their
                    discretion.
                </p>
                <p>
                    No promotional news articles will be accepted as our service
                    should not be used for your advertisements. Company's
                    website URL that contains pop-up and pop-unders ads also
                    will not be accepted. Such news articles will be removed
                    from our database without any notifications. Members are not
                    allowed to misuse our service in order to promote their
                    services violating news article submission conditions.
                    Violation will result in permanent removal of account and
                    their releases.
                </p>

                <h3 class="text-center ma-8">Headline and Summary</h3>
                <ul>
                    <li>
                        <p>
                            Headline must not be too long (under 80 characters),
                            and have active verb and subject.
                        </p>
                    </li>
                    <li>
                        <p>
                            Headline should accurately present news article's
                            content.
                        </p>
                    </li>
                    <li>
                        <p>
                            Headline must be newsworthy (important and recent
                            enough to be announced).
                        </p>
                    </li>
                    <li>
                        <p>
                            Summary should clearly describe news article's
                            content.
                        </p>
                    </li>
                    <li>
                        <p>Your summary should be one to two sentences long.</p>
                    </li>
                    <li>
                        <p>
                            Please do not use first paragraph as summary
                            paragraph.
                        </p>
                    </li>
                </ul>

                <h3 class="text-center ma-8">News Article Format</h3>
                <ul>
                    <li>
                        <p>
                            News Article word count should be between 300 to 800
                            words.
                        </p>
                    </li>
                    <li>
                        <p>
                            News Article should be objective - don't use casual
                            first person language (I, we, you, etc.)
                        </p>
                    </li>
                    <li>
                        <p>
                            Attach only one image per news article not more than
                            200KB in file size (jpg, png or gif only).
                        </p>
                    </li>
                    <li>
                        <p>
                            Video should be embedded correctly into news
                            article.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should have a legitimate news angle
                            (announcing something new and/or timely).
                        </p>
                    </li>
                    <li>
                        <p>
                            Don't write your news article like an advertisement
                            or a sales letter.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should keep an impartial tone throughout the
                            news article.
                        </p>
                    </li>
                    <li>
                        <p>Please don't ask question(s) in a news article.</p>
                    </li>
                    <li>
                        <p>
                            Use uppercase and lowercase lettering appropriately.
                        </p>
                    </li>
                    <li>
                        <p>
                            Avoid inserting JavaScript or other markup languages
                            in your news article, such as
                            &lt;strong&gt;…&lt;/strong&gt; or
                            &lt;em&gt;…&lt;/em&gt;. ONLY links eg. &lt;a
                            href="URL"&gt;…&lt;/a&gt; are acceptable.
                        </p>
                    </li>
                    <li>
                        <p>Please ensure links are formatted correctly.</p>
                    </li>
                    <li>
                        <p>
                            Make sure each paragraph is spaced out with a line.
                        </p>
                    </li>
                    <li>
                        <p>
                            Please ensure news article is properly formatted,
                            e.g., do not double space unnecessarily. Use
                            appropriate punctuation and symbols.
                        </p>
                    </li>
                    <li>
                        <p>
                            Only include a quote that someone actually might
                            have said.
                        </p>
                    </li>
                    <li>
                        <p>
                            News Article links should be limited to 1 per 200
                            words.
                        </p>
                    </li>
                    <li>
                        <p>
                            News Articles should not contain references or links
                            to sexually explicit material, illegal material or
                            profane language.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should not have intent to harm or exact
                            personal revenge against a person or group.
                        </p>
                    </li>
                    <li>
                        <p>
                            Spam related words that will be filtered out by spam
                            filters should be refrained.
                        </p>
                    </li>
                    <li>
                        <p>
                            Include available contact information where
                            possible.
                        </p>
                    </li>
                    <li>
                        <p>
                            Do not place email addresses within the body of the
                            news article.
                        </p>
                    </li>
                    <li>
                        <p>
                            Include the city from where the release originates,
                            usually the location of the company's headquarters.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should contain no or few grammatical and
                            spelling errors.
                        </p>
                    </li>
                    <li>
                        <p>
                            Do not use special characters and symbols in
                            headline such as ™ or ©.
                        </p>
                    </li>
                </ul>

                <h3 class="text-center ma-6">Content</h3>
                <ul>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            contain only opinion.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that are
                            duplicated content.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            promote mortgage, pay day, cash, short-term or
                            long-term loan services.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            promote escort, sexual enhancement products or
                            supplements.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases
                            containing radical or political opinion/view.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases
                            containing radical religious opinion/view.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases with
                            intent to harm or defame.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases
                            containing unauthorized stock ticker symbols.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            promote online gambling.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept topics regarding
                            e-cigs, green coffee, raspberry ketones, HCG,
                            Kratom, Garcinia Cambogia and more.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should keep an impartial tone throughout the
                            news article.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should contain no or few grammatical and
                            spelling errors.
                        </p>
                    </li>
                    <li>
                        <p>
                            Don't write your news article like an advertisement
                            or a sales letter.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept topics regarding
                            illegal device unlocking, rooting or jail-breaking
                            of devices.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            announces discounts, bonus, price cuts, sales,
                            coupon codes or anything related to promotion of
                            products/services, advertorials or news about
                            promotions.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept content that
                            reads like an article without news value.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept content about
                            affiliate product reviews, product reviews or
                            trials.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept content about new
                            website launches, new blog posts or content that
                            generally does not carry a news value.
                        </p>
                    </li>
                </ul>
                <p>
                    Disclaimer: Under any circumstances, the
                    {{ businessName }} team reserve the right at all times and
                    without previous notification to approve, disapprove or
                    remove parts or all the published/submitted documents as
                    long as it is inappropriate to be published. Any decision
                    made by the {{ businessName }} team shall be FINAL and
                    cannot be questioned.
                </p>
                <p>
                    These terms will change from time to time without prior
                    notice and you are advised to visit this page for updates.
                </p>

                <h3 class="text-center ma-6">General Disclaimer</h3>
                <p>
                    Our products, services, and training evolve over time to
                    adapt to changing market conditions. As such, we cannot
                    guarantee marketing claims or availability of specific
                    content brands, but we do guarantee best efforts in
                    replacing any with equivalent alternatives.
                </p>

                <div v-if="isAmpifire">
                    <h3 class="text-center mt-6 mb-4">Contact Us</h3>
                    <ul class="pb-4">
                        <li>AmpiFire</li>
                        <li>
                            <a :href="'mailto:' + businessEmail">
                                {{ businessEmail }}
                            </a>
                        </li>
                        <li>
                            UK Office: 15 Harwood Road, Fulham, London, SW6 4QP.
                        </li>
                        <li>
                            HK Office: 14/F China Hong Kong Tower, 8 Hennessy
                            Road, Wanchai.
                        </li>
                    </ul>
                </div>
                <div v-if="shouldAgree" class="text-center my-6">
                    <a-form :submit="save">
                        <a-toggle
                            v-model="agreed_to_tos"
                            checkbox
                            :disabled="isSaving"
                            label="I agree to the Terms of Use Agreement"
                        />
                        <div class="text-center">
                            <v-btn
                                type="submit"
                                color="primary"
                                large
                                :loading="isSaving"
                                :block="$vuetify.breakpoint.smAndDown"
                                :disabled="!agreed_to_tos"
                            >
                                Agree
                            </v-btn>
                        </div>
                    </a-form>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import { isClientCabin, getScrollParent } from '@/utils/helpers';

import { RootState } from '@/store/State';

import { AForm } from '@/components/AForm';
import { AToggle } from '@/components/AForm/Inputs/AToggle';
import { Watch } from '@/utils/decorators';

@Component({
    components: {
        AForm,
        AToggle
    },
    computed: {
        ...mapState({
            userId: (state): number | void => (state as RootState).user?.id
        }),
        ...mapState('user', ['businessName', 'businessEmail'])
    }
})
export default class TermsOfService extends Vue {
    userId!: number;
    businessName!: string | null;
    businessEmail!: string | null;

    isAmpifire = !isClientCabin();

    shouldAgree = false;

    agreed_to_tos = false;

    isSaving = false;

    @Watch('userId')
    onUserId(id: number) {
        if (id) {
            this.handleAgreement();
        }
    }

    mounted() {
        if (window.location.hash) {
            setTimeout(() => {
                this.scrollTo(window.location.hash);
            }, 1000);
        }
    }

    scrollTo(href: string) {
        const element = document.getElementById(href.replace('#', ''));

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container,
                    offset: 20
                });
            }
        }
    }

    handleAgreement() {
        if (this.userId) {
            this.getUserData().then(({ data }) => {
                this.agreed_to_tos = Boolean(data.user?.agreed_to_tos || false);

                if (!this.agreed_to_tos) {
                    this.shouldAgree = true;
                }
            });
        }
    }

    async getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
                return {};
            });
    }

    getDataToSave() {
        return (({ agreed_to_tos }) => ({
            agreed_to_tos: Number(agreed_to_tos)
        }))(this);
    }

    save() {
        this.setSaving();

        return this.$http
            .post('/users/panel', this.getDataToSave())
            .then(({ data }) => {
                if (!data) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save your agreement to our Terms of Service. Please try again later.'
                    );
                }

                this.shouldAgree = false;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }
}
</script>

<style lang="scss" scoped>
.terms::v-deep {
    #privacy,
    #cookies {
        scroll-margin-top: 6rem;
    }
}
</style>
