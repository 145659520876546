<template>
    <v-container :class="{ clientcabin: isClientCabin }" fluid>
        <v-row justify="center">
            <v-col v-if="isClientCabin" cols="12" lg="9" xl="7">
                <terms-of-service />
            </v-col>
            <v-col v-else>
                <terms-of-service />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { isClientCabin } from '@/utils/helpers';

import TermsOfService from '@/components/TermsOfService';

@Component({
    components: {
        TermsOfService
    }
})
export default class TermsOfServicePage extends Vue {
    isClientCabin = isClientCabin();
}
</script>

<style lang="scss" scoped>
.clientcabin::v-deep {
    color: $black;
    background-color: $white;

    a {
        display: inline-block;
    }

    .v-label.theme--light {
        color: $black;
    }
}
</style>
